import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect'
import BackgroundImage from 'gatsby-background-image'
import { get } from 'lodash'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useStaticQuery, graphql } from 'gatsby'

import media, { breakpoints } from '../../../utils/media'
import CreerAlerteForm from '../CreerAlerteForm'
import ProgramPlaceMobileButton from '../../molecules/ProgramPlaceMobileButton'

const DEMANDE_ZAPIER = 'https://hooks.zapier.com/hooks/catch/2114713/3rby1mm/'

const Container = styled.div`
  display: flex;
  padding-top: 20px;
  width: 100%;

  ${media.greaterThan('lg')`
    width: 100%;
    position: fixed;
  `}

  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`
const StyledImageSection = styled(BackgroundImage)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  min-height: 440px;

  ${media.greaterThan('lg')`
    height: 100vh;
    position: fixed; 
    overflow-y: auto;
    top: 0; right: 0; bottom: 0; left: 0;
  `}

  ${media.lessThan('lg')`
    width: 100%;
    margin-bottom: 24px;
  `}
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: 'Source Sans Pro';
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
  color: ${({ theme }) => get(theme, 'blue')};
  background:  ${({ theme }) => get(theme, 'white')};
  padding: 16px 24px;
  margin: 0 auto;
  border-radius: 25px;
  gap: 8px;
  max-height: 300px;

  ${media.lessThan('xl')`
    font-size: 28px;
  `}

  ${media.lessThan('lg')`
    display: none;
  `}
`
const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.greaterThan('lg')`
    padding-top: 55px;
  `}
`
const DataItem = styled.div`
  max-width: 268px;
  margin-bottom: 8px;
  border-radius: 15px;
  padding: 11px 16px;
  background-color: ${({ theme }) => get(theme, 'white', '#FFF')};
`
const DataText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3em;
  color: ${({ theme }) => get(theme, 'blue')};

  ${media.lessThan('lg')`
    font-size: 16px;
    line-height: 1.19;
  `}

  & > b {
    color: ${({ theme }) => get(theme, 'brightOrange')};
  }

  & > span {
    font-size: 16px;
    font-weight: normal;

    ${media.lessThan('lg')`
      font-size: 14px;
    `}
  }
`
const FormSection = styled.div`
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 4px;

  ${media.greaterThan('lg')`
    max-height: 100vh;
  `}

  ${media.lessThan('lg')`
    padding: 32px 0;
    align-items: center;
    justify-content: center;
  `}
`
const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${media.greaterThan('lg')`
    max-width: 325px;
  `}

  ${media.lessThan('lg')`
    gap: 8px;
    padding:0px 40px;
  `}
`
const HeaderTitle = styled.h2`
  font-family: 'Source Sans Pro';
  font-size: 26px;
  margin: 0;
  font-weight: bold;
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};

  ${media.lessThan('md')`
    font-size: 20px;
  `}

  ${media.lessThan('sm')`
    font-size: 18px;
  `}

  ${media.greaterThan('xxl')`
    font-size: 36px;
  `}
`
const HeaderSubTitle = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  margin: 2px 0;
  font-weight: ${({ bold }) => bold ? '600' : '400'};
  color: ${({ theme }) => get(theme, 'blue')};

  ${media.lessThan('sm')`
    line-height: 1.5;
  `}

  ${media.greaterThan('xxl')`
    margin: 4px 0;
  `}
`
const Form = styled(CreerAlerteForm)`
  ${media.lessThan('lg')`
    padding: 0px 40px;
  `}

  ${media.greaterThan('xxl')`
    margin-top: 4px;
  `}
`

const Text = styled.p`
  margin: 0;
  padding: 0;
  color: ${({ theme, color }) => get(theme, color || 'blue', '#fff')};
  ${({ bold }) => bold ? 'font-weight: 600' : ''};
`

const AdPlaceHeader = () => {
  return (
    <FormHeader>
      <HeaderTitle>
        Créer une alerte
      </HeaderTitle>
      <HeaderSubTitle>
        Quel est votre projet et sur quelles coordonnées pouvons-nous vous informer des opportunités?
      </HeaderSubTitle>
    </FormHeader>
  )
}

const AdPlaceImage = ({ placeholderImage }) => {
  return (
    <StyledImageSection
      alt='Locaux professions libérales - demande accompagnement avec un expert'
      Tag='div'
      {...convertToBgImage(getImage(placeholderImage))}
      preserveStackingContext>
      <Title>
        Locaux Professions Libérales
      </Title>
      <DataContainer>
        <DataItem>
          <DataText>
            Spécialiste de l’immobilier pour les libéraux
          </DataText>
        </DataItem>
        <DataItem>
          <DataText>
            <Text bold={true}>Locaux de 10 à 400 m2</Text>
            <Text bold={true} color='brightOrange'>A vendre ou à louer</Text>
            <span>Centre Ville, Accès PMR, Parking</span>
          </DataText>
        </DataItem>
      </DataContainer>
    </StyledImageSection>
  )
}

const getMinMediaQuery = (size) => {
  if (typeof window !== 'undefined') {
    return window.matchMedia('(min-width: ' + get(breakpoints, size))
  }

  return {
    matches: false,
    addListener: () => { },
    removeListener: () => { }
  }
}

const CreerAlerteContent = () => {
  const hiddenRef = useRef()
  const [bigScreen, updatebigScreen] = useState(getMinMediaQuery('lg').matches)

  useAsyncEffect(async () => {
    const mql = getMinMediaQuery('lg')
    const resize = (event) => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  })

  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "adPlace/background.webp"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
  `)

  return (
    <>
      <Container>
        {bigScreen && <AdPlaceImage placeholderImage={placeholderImage} />}
        <FormSection>
          <AdPlaceHeader />
          {!bigScreen && <AdPlaceImage placeholderImage={placeholderImage} />}
          <Form
            redirection='/nos-annonces/creer-une-alerte/success'
            gaEventName='envoi_formulaire_creation_alerte'
            hiddenRef={hiddenRef}
            submit={DEMANDE_ZAPIER} />
        </FormSection>
      </Container>
      <ProgramPlaceMobileButton hiddenRef={hiddenRef} />
    </>
  )
}

export default CreerAlerteContent
